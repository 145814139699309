import {
  differenceInDays,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  format,
  setHours,
  setMinutes,
} from "date-fns";

/**
 * 날짜를 특정 패턴으로 변환
 * @param date
 * @param pattern
 */
export const dateToString = (
  date: string | null | undefined,
  pattern: string | null | undefined
) => {
  if (!date || !pattern) return "";

  const newDate = typeof date === "string" ? new Date(date) : date;

  return format(newDate, pattern);
};

/**
 * 초를 시분초로 변환
 * @param seconds
 * @param pattern
 */

export const secondsToHhmmss = (seconds: number | null | undefined) => {
  if (!seconds) return "00:00:00";

  return new Date(seconds * 1000).toISOString().substring(11, 19);
};

/**
 * 두 시간 사이의 간격을 월로 반환
 * @param start
 * @param end
 */
export const dateDifferenceInMonths = (start: any, end: any) => {
  if (!start || !end) return 0;

  const newStart = typeof start === "string" ? new Date(start) : start;
  const newEnd = typeof end === "string" ? new Date(end) : end;

  return differenceInMonths(newEnd, newStart);
};

/**
 * 두 시간 사이의 간격을 일로 반환
 * @param start
 * @param end
 */
export const dateDifferenceInDays = (start: any, end: any) => {
  if (!start || !end) return 0;

  const newStart = typeof start === "string" ? new Date(start) : start;
  const newEnd = typeof end === "string" ? new Date(end) : end;

  return differenceInDays(newEnd, newStart);
};

/**
 * 두 시간 사이의 간격을 초로 반환
 * @param start
 * @param end
 */
export const dateDifferenceInSeconds = (start: any, end: any) => {
  if (!start || !end) return 0;

  const newStart = typeof start === "string" ? new Date(start) : start;
  const newEnd = typeof end === "string" ? new Date(end) : end;

  return differenceInSeconds(newEnd, newStart);
};

/**
 * 두 시간 사이의 간격을 분으로 반환
 * @param start
 * @param end
 */
export const dateDifferenceInMinutes = (start: any, end: any) => {
  if (!start || !end) return 0;

  const newStart = typeof start === "string" ? new Date(start) : start;
  const newEnd = typeof end === "string" ? new Date(end) : end;

  return differenceInMinutes(newEnd, newStart);
};

/**
 * DateTimePicker 기본 선택값
 */
export const initialFocusedDate = setMinutes(new Date(), 0);
export const initialFocusedStartDateTime = setHours(
  setMinutes(new Date(), 0),
  0
);
export const initialFocusedEndDateTime = setHours(
  setMinutes(new Date(), 59),
  23
);

/**
 * 기준 일자가 포함된 주의 첫번째 일자
 * @param date
 */
export const startDateOfWeek = (date?: string | null | undefined) => {
  const now = !date
    ? new Date()
    : typeof date === "string"
    ? new Date(date)
    : date;
  const nowDayOfWeek = now.getDay();

  return new Date(
    now.setDate(now.getDate() - (nowDayOfWeek === 0 ? 6 : nowDayOfWeek - 1))
  );
};

/**
 * 기준 일자가 포함된 주의 마지막 일자
 * @param date
 */
export const endDateOfWeek = (date?: string | null | undefined) => {
  const now = !date
    ? new Date()
    : typeof date === "string"
    ? new Date(date)
    : date;
  const nowDayOfWeek = now.getDay();

  return new Date(
    now.setDate(now.getDate() + (nowDayOfWeek === 0 ? 0 : 7 - nowDayOfWeek))
  );
};

/**
 * 기준 일자가 포함된 달의 첫번째 일자
 * @param date
 */
export const startDateOfMonth = (date?: string | null | undefined) => {
  const now = !date
    ? new Date()
    : typeof date === "string"
    ? new Date(date)
    : date;
  return new Date(now.getFullYear(), now.getMonth(), 1);
};

/**
 * 기준 일자가 포함된 달의 마지막 일자
 * @param date
 */
export const endDateOfMonth = (date?: string | null | undefined) => {
  const now = !date
    ? new Date()
    : typeof date === "string"
    ? new Date(date)
    : date;
  return new Date(now.getFullYear(), now.getMonth() + 1, 0);
};

/**
 * 기준 일자에서 n일 만큼 더하기
 * @param date
 * @param days
 */
export const addDays = (
  date?: string | null | undefined,
  days?: number | null | undefined
) => {
  if (!date) return "";
  if (!days) return date;

  const newDate = typeof date === "string" ? new Date(date) : date;

  newDate.setDate(newDate.getDate() + days);

  return newDate;
};

/**
 * 기준 일자에서 n주 만큼 더하기
 * @param date
 * @param weeks
 */
export const addWeeks = (
  date?: string | null | undefined,
  weeks?: number | null | undefined
) => {
  if (!date) return "";
  if (!weeks) return date;

  const newDate = typeof date === "string" ? new Date(date) : date;

  newDate.setDate(newDate.getDate() + weeks * 7);

  return newDate;
};

/**
 * 기준 일자에서 n달 만큼 더하기
 * @param date
 * @param months
 */
export const addMonths = (
  date?: string | null | undefined,
  months?: number | null | undefined
) => {
  if (!date) return "";
  if (!months) return date;

  const newDate = typeof date === "string" ? new Date(date) : date;

  newDate.setMonth(newDate.getMonth() + months);

  return newDate;
};

/**
 * 기준 일자에서 n년 만큼 더하기
 * @param date
 * @param years
 */
export const addYears = (
  date?: string | null | undefined,
  years?: number | null | undefined
) => {
  if (!date) return "";
  if (!years) return date;

  const newDate = typeof date === "string" ? new Date(date) : date;

  newDate.setFullYear(newDate.getFullYear() + years);

  return newDate;
};
