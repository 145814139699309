import React, {SyntheticEvent, useEffect, useState} from "react";
import clsx from "clsx";
import UAParser from "ua-parser-js";

interface ThumbnailProps {
    id?: string;
    alt?: string;
    className?: string;
    errorImage?: string;
    onClick?: Function;
}

export default function Thumbnail(props: ThumbnailProps) {
    const {id, alt, className, errorImage, onClick} = props;
    const [isIE, setIE] = useState<boolean>(false);

    useEffect(() => {
        const parser = new UAParser();
        setIE(parser.getBrowser().name === 'IE' || parser.getBrowser().name === 'IEMobile');
    }, []);

    // 이미지 오류 처리
    const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = errorImage ? errorImage : "/images/common/img-320-list-noimg.png";
    };

    return (
        <img
            className={clsx({["opacity-0"]: isIE}, (className ? className : ''), (onClick && 'cursor-pointer'))}
            alt={alt ? alt : ''}
            src={id ? `/api/file/${id}?accessToken=ok` : '/images/common/img-320-list-noimg.png'}
            onError={handleImageError}
            onClick={() => !!onClick && onClick()}
        />
    );
};
