import axios from "axios";

export const logout = async () => {
    // window.location.href = process.env.NEXT_PUBLIC_KEYCLOAK_LOGOUT_URI;
    document.cookie = "otp_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    const params = new URLSearchParams();

    await axios
        .post("/logout", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .finally(() => {
            window.location.href = "/";
        });
};
