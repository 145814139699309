import styles from "./SubjectCardForExplore.module.css";
import { Subject } from "components/types/Subject";
import {
  FavoriteTargetType,
  getSubjectTypeCodeName,
} from "shared/utils/CommonCode";
import { Keyword } from "components/types/Keyword";
import { useTranslation } from "next-i18next";
import Thumbnail from "components/common/Thumbnail";
import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { useRouter } from "next/router";
import { gql, useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import { Sequence } from "components/types/Sequence";
import { useUserContext } from "shared/user-context";
import { dateToString } from "shared/utils/DateUtil";

// 좋아요 토글
const TOGGLE_FAVORITE = gql`
  mutation ToggleFavorite($request: ToggleFavoriteInput!) {
    favorite: toggleFavorite(request: $request) {
      id
    }
  }
`;

// CSS
const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: 315,
    height: 255,
    "@media (max-width: 1280px)": {
      width: 155,
      height: 125,
    },
  },
}));

interface Props {
  subject: any;
  link?: string;
  isQuerySend?: any;
  isTotalSearch?: boolean;
  referer?: string;
  list?: boolean;
}

export default function SubjectCardForExplore(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useRouter();
  const { query } = useRouter();
  const { user } = useUserContext();
  const { isTotalSearch, list } = props;

  const [subject, setSubject] = useState(props.subject);

  // 과정 차수 목록
  const sequences = useMemo(
    () =>
      subject?.sequences?.filter((sequence: Sequence) => {
        if (
          sequence &&
          ((!sequence.always &&
            sequence.applicationStartDateTime &&
            sequence.applicationEndDateTime &&
            sequence.cancelStartDateTime &&
            sequence.cancelEndDateTime &&
            sequence.studyStartDateTime &&
            sequence.studyEndDateTime &&
            Number(sequence.fixedNumberOfStudent) > 0 &&
            sequence.openToThePublic === true) ||
            (sequence.always && sequence.alwaysStudyDay))
        ) {
          return sequence;
        }
      }),
    [subject]
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const fnMouseEnter = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const fnMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // 좋아요 토글
  const [fnToggleFavorite] = useMutation(TOGGLE_FAVORITE, {
    onCompleted: (data) => {
      if (data?.favorite && subject) {
        setSubject({
          ...subject,
          myFavorite: !subject.myFavorite,
          favorites:
            Number(subject?.favorites || 0) + (subject.myFavorite ? -1 : 1),
        });
      }
    },
  });

  // 찜 목록에 추가
  const fnAddFavorite = () => {
    let request = {
      targetType: FavoriteTargetType.SUBJECT,
      targetId: String(subject?.id),
    };

    // 좋아요 토글
    fnToggleFavorite({ variables: { request: { ...request } } });
  };

  // 플레이리스트에 추가
  const fnAddPlayList = () => {
    // TODO: hlee - 플레이리스트에 추가
  };

  // 상세화면으로 이동
  const fnGoDetail = () => {
    push({
      pathname: `/explore/${subject.id}`,
      query: {
        sequenceId: titleSequence?.id,
        tab: 'ALL',
      },
    });
  };

  const titleSequence = isTotalSearch ? 
    { 
      id: subject?.recentSequenceId,
      name: subject?.recentSequenceName,
      studyStartDateTime: subject?.recentSequenceStudyStartDateTime,
      studyEndDateTime: subject?.recentSequenceStudyEndDateTime,
      applicationStartDateTime: subject?.recentSequenceApplicationStartDateTime,
      applicationEndDateTime: subject?.recentSequenceApplicationEndDateTime,
    }
  
  : subject?.exploreSequences;

  return (
    <div className={clsx(styles.cptThumPrc, isTotalSearch && styles.searchType)}>
      <div className={styles.thumImgGroup}>
        <div className={clsx(styles.imgBox, "relative")}>
          <div
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={fnMouseEnter}
            onMouseLeave={fnMouseLeave}
            className="relative w-full"
          >
            <Thumbnail
              id={isTotalSearch ? subject?.thumbnailFileId : subject?.thumbnail?.id}
              alt={subject?.name}
              className={classes.thumbnail}
              onClick={fnGoDetail}
            />
            <div
              className={clsx("inset-0 w-full h-full hidden", {
                ["!inline"]: open,
              })}
            >
              <ul className={styles.infoBox}>
                {user?.authenticated && (
                  <React.Fragment>
                    <li
                      className={clsx(styles.infoLike, {
                        [styles.on]: subject?.myFavorite,
                      })}
                    >
                      <button className={styles.icon} onClick={fnAddFavorite}>
                        {t("찜")}
                      </button>
                      <span className={styles.cmt}>
                        <span className={styles.offView}>
                          {t("찜 목록에 추가")}
                        </span>
                        <span className={styles.onView}>
                          {t("찜 목록에 추가됨")}
                        </span>
                      </span>
                    </li>
                    {/* TODO: hlee - 플레이리스트에 추가 */}
                    {/*
                                        <li className={clsx(styles.infoPlay, {[styles.on]: subject?.myPlayList})}>
                                            <button className={styles.icon} onClick={fnAddPlayList}>{t('플레이리스트')}</button>
                                            <span className={styles.cmt}>
                                                <span className={styles.offView}>{t('플레이리스트에 추가')}</span>
                                                <span className={styles.onView}>{t('플레이리스트에 추가됨')}</span>
                                            </span>
                                        </li>
                                        */}
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.imgInfo}>
          <div className={styles.badgeBox}>
            {subject?.isNew && <div className={styles.badgeBlue}>NEW</div>}
            {subject?.type && (
              <div className={styles.badgeBlack}>
                {t(getSubjectTypeCodeName[subject?.type] == "조합교육" ? "온라인교육" : getSubjectTypeCodeName[subject?.type])}
              </div>
            )}
          </div>
          <div className={styles.infoBox}>
            {`학습기간 : ${dateToString(
              titleSequence?.studyStartDateTime,
              "yyyy-MM-dd"
            )} ~ ${dateToString(
              titleSequence?.studyEndDateTime,
              "yyyy-MM-dd"
            )}`}
          </div>
        </div>
      </div>
      <div className={styles.thumContGroup}>
        <div className={styles.thumTitle}>
          <button className="cursor-pointer" onClick={fnGoDetail} tabIndex={0}>
            <h4>{titleSequence?.name}</h4>
          </button>
        </div>
        <div className={styles.thumEtc}>
          <span className={styles.free}>{`${
            subject?.cost > 0 ? t(`유료`) : t(`무료`)
          } | ${
            new Date(titleSequence?.applicationStartDateTime) <= new Date() &&
            new Date() <= new Date(titleSequence?.applicationEndDateTime)
              ? "신청가능"
              : "신청불가"
          } | ${subject?.educationTime || 0}h`}</span>
          <span className={styles.hide}>{t(`좋아요`)}:</span>
          <span className={subject?.myFavorite ? styles.liked : styles.like}>
            {subject?.favorites?.toLocaleString() || 0}
          </span>
          <span className={styles.hide}>{t(`조회수`)}:</span>
          <span className={styles.view}>
            {subject?.views?.toLocaleString() || 0}
          </span>
        </div>
      </div>
    </div>
  );
}
