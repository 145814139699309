import React, {useEffect} from "react";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {objCookies} from "shared/utils/CommonUtil";
import {dateToString} from "shared/utils/DateUtil";
import {useUserContext} from "shared/user-context";
import {v4 as uuid} from 'uuid';
import {CompanyGroup} from "components/types/CompanyGroup";
import {useRouter} from "next/router";

// 로그인 이력 등록
const CREATE_LOGIN_HISTORY = gql`
    mutation CreateLoginHistory($request: CreateLoginHistoryInput!) {
        login: createLoginHistory(request: $request) {
            id
        }
    }
`;

// 주관처 목록 조회
const GET_COMPANY_GROUP_LIST = gql`
    query GetCompanyGroupList($request: GetCompanyGroupListInput!) {
        companyGroups: getCompanyGroupLists(request: $request) {
            id
            name
            domain
            targetCompanies {
                id
                name
            }
            basic
        }
    }
`;

interface Props {
};

/**
 * 로그인 체크
 * @constructor
 */
export default function CheckLogin(props: Props) {
    const {user} = useUserContext();
    const {asPath, push} = useRouter();

    // 로그인 이력 등록
    const [createLoginHistory] = useMutation(CREATE_LOGIN_HISTORY);

    // 주관처 목록 조회
    const [fnGetCompanyGroupList] = useLazyQuery(GET_COMPANY_GROUP_LIST, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (data?.companyGroups?.length > 0 && data?.companyGroups?.filter((companyGroup: CompanyGroup) => !!companyGroup?.basic)?.length === 0) {
                document.cookie = 'previousUrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
                document.cookie = `previousUrl=${encodeURIComponent(asPath)}; path=/;`
                push('/login');
            }
        }
    });

    useEffect(() => {
        if (user) {
            if (user.authenticated) {
                const cookieName = `${dateToString(new Date().toString(), 'yyyyMMdd')}-${String(user?.id)}`;
                const cookies = objCookies.get({name: cookieName});

                if (cookies === undefined) {
                    objCookies.add({
                        name: cookieName,
                        value: uuid(),
                    });

                    // 로그인 이력 등록
                    createLoginHistory({variables: {request: {loginId: String(user?.loginId)}}});
                }
            } else {
                // 주관처 목록 조회
                fnGetCompanyGroupList({
                    variables: {
                        request: {
                            domain: window.document.location.host,
                            limit: 0,
                        }
                    }
                });
            }
        }
    }, [user]);

    return (
        <React.Fragment></React.Fragment>
    );
};
